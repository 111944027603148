<template>
  <div
    class="tilted-image"
    :class="direction === 'left' ? 'left-img' : 'right-img'"
  >
    <video
      src="https://firebasestorage.googleapis.com/v0/b/tafadzwa-mhizha.appspot.com/o/vids%2Fbazar-promo.mp4?alt=media&token=e0e21a04-e211-4f57-809d-1bfe00119a16"
      :class="direction === 'left' ? 'tilted-image-left' : 'tilted-image-right'"
      alt="project-image"
      loading="lazy"
      autoplay
      loop
      muted
      v-if="type == 'vid'"
    ></video>

    <img
      v-else
      :src="image"
      :class="direction === 'left' ? 'tilted-image-left' : 'tilted-image-right'"
      alt="project-image"
      loading="lazy"
    />
    <img
      :src="require(`@/assets/svg/${grid}`)"
      alt="grid"
      :class="direction === 'left' ? 'grid' : 'grid-right'"
      class="grid-img"
    />
    <p
      :class="direction === 'left' ? 'hanging-text-left' : 'hanging-text-right'"
      class="hanging-text"
    >
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  props: ['direction', 'text', 'image', 'type'],

  data() {
    return {
      grid: this.direction === 'left' ? 'grid-gray.svg' : 'grid-blue.svg',
    };
  },
};
</script>

<style lang="scss" scoped>
.tilted-image {
  padding: 0 6.8rem;
  margin: 3rem 0 10.7rem;
  position: relative;

  @media screen and (max-width: 600px) {
    margin: 3rem 0 6rem;
    padding: 0 3rem;
  }

  &-left {
    position: relative;
    width: 63%;
    border-radius: 0.7rem;
    z-index: 10;
    box-shadow: 11px 15px 27px rgba(0, 0, 0, 0.16);
    transform: perspective(900px) rotateY(2deg);

    @media screen and (max-width: 600px) {
      width: 90%;
    }
  }

  .grid-img {
    position: absolute;
    width: 57%;

    @media screen and (max-width: 600px) {
      width: 80%;
    }

    @media screen and (max-width: 380px) {
      width: 78%;
      height: 70%;
    }
  }

  .grid {
    bottom: 2%;
    left: 11%;
    // transform: scale(0.9);

    @media screen and (max-width: 380px) {
      left: 15%;
    }
  }

  .hanging-text {
    position: absolute;
    font-family: var(--font-header);
    color: var(--color-grey);
    font-size: 1.9rem;
    width: 22%;
    top: 0;

    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  .hanging-text-left {
    right: 6.8rem;
  }

  &-right {
    position: relative;
    margin-left: auto;
    display: block;
    width: 63%;
    border-radius: 0.7rem;
    z-index: 10;
    box-shadow: -11px 15px 27px rgba(0, 0, 0, 0.16);
    transform: perspective(900px) rotateY(-2deg);

    @media screen and (max-width: 600px) {
      width: 90%;
    }
  }

  .grid-right {
    bottom: 2%;
    right: 11%;

    @media screen and (max-width: 380px) {
      right: 15%;
    }
  }

  .hanging-text-right {
    left: 6.8rem;
  }
}
</style>