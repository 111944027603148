<template>
  <page-container>
    <div class="project-container">
      <div
        class="hero"
        :style="{
          backgroundImage: `url(${cover})`,
        }"
      ></div>

      <h4 class="title">{{ project.name }}</h4>

      <p class="description">
        {{ project.intro }}
      </p>

      <div class="services">
        <div class="header">
          <h3 class="header-title">Services</h3>
          <div class="header-background" :class="project.class"></div>
        </div>
        <p
          class="service"
          v-for="(service, index) in project.services"
          :key="index"
        >
          {{ service }}
        </p>
      </div>

      <div class="project-image-container" :class="{ first: project.carousel }">
        <img
          :src="logoImage"
          class="project-image"
          alt="project-image"
          loading="lazy"
        />
        <div class="cover"></div>
      </div>

      <image-slider
        v-if="project.slider && skinnyMobile"
        :project="project"
      ></image-slider>

      <carousel
        v-if="project.carousel && !skinnyMobile"
        :project="project"
        :numImages="project.carousel.length"
      ></carousel>

      <p class="description">
        {{ project.brief[0] }}
      </p>

      <img
        v-if="project.carousel"
        :src="project.images[1]"
        class="project-image"
        alt="project-image"
        loading="lazy"
      />

      <p class="description" v-if="project.carousel">
        {{ project.brief[3] }}
      </p>

      <tilted-card
        direction="left"
        :image="project.images.slice(-2)[0]"
        :text="project.pictureAlt[0]"
        :type="project.name == 'bazar' ? 'vid' : 'image'"
      ></tilted-card>

      <p class="description">
        {{ project.brief[1] }}
      </p>

      <tilted-card
        direction="right"
        :image="project.images.slice(-2)[1]"
        :text="project.pictureAlt[1]"
      ></tilted-card>

      <p class="description">
        {{ project.brief[2] }}
      </p>

      <a :href="project.link" target="_blank" class="btn">Visit Site</a>

      <scroll-top></scroll-top>
    </div>
  </page-container>
</template>

<script>
import gsap from 'gsap';
import CSSRulePlugin from 'gsap/CSSRulePlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { mapGetters } from 'vuex';
import TiltedCard from '@/components/TiltedCard.vue';
import Carousel from '@/components/Carousel.vue';
import ImageSlider from '@/components/ImageSlider.vue';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CSSRulePlugin);
export default {
  props: ['projectId'],

  components: {
    TiltedCard,
    Carousel,
    ImageSlider,
  },

  data() {
    return {
      skinnyMobile: false,
    };
  },

  computed: {
    project() {
      return this.PROJECTS().find((el) => el.name === this.projectId);
    },

    cover() {
      if (this.skinnyMobile) {
        return this.project.cover.mobile.story;
      }
      return this.project.cover.desktop;
    },

    logoImage() {
      if (this.skinnyMobile) {
        return this.project.images[0].mobile;
      }
      return this.project.images[0].desktop;
    },
  },

  methods: {
    ...mapGetters(['PROJECTS']),

    checkScreen() {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 500) {
        this.skinnyMobile = true;
        return;
      }
      this.skinnyMobile = false;
    },
  },

  mounted() {
    // ANIMATIONS
    gsap.to('.cover', {
      height: 0,
      duration: 1.25,
      scrollTrigger: {
        trigger: '.project-image',
        start: '45% 95%',
      },
    });

    gsap.from('.tilted-image-left', {
      rotateY: '-2deg',
      duration: 7,
      ease: 'Linear.easeInOut',
      repeat: -1,
      yoyo: true,
    });

    gsap.from('.tilted-image-right', {
      rotateY: '2deg',
      duration: 7,
      ease: 'Linear.easeInOut',
      repeat: -1,
      yoyo: true,
    });
  },

  created() {
    this.checkScreen();
    window.addEventListener('resize', this.checkScreen);
  },
};
</script>

<style lang="scss" scoped>
.project-container {
  .project-image {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: center;
    margin-bottom: 8.3rem;
  }

  .first {
    margin-bottom: 0 !important;
  }

  .cover {
    width: 100%;
    height: 100%;
    background: var(--color-primary);
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
</style>